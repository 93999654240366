export const enviornment = {
  production: false,
  dev: true,
  qa: false,
  local: false,
  apiUrl: 'https://dev-referral-api-aperture.gspann.com',
};

export const environmentLMS = {
production: false,
  dev: true,
  qa: false,
  local: false,
  apiUrl: 'https://dev-lms-api-aperture.gspann.com/aperture-leave',
}

export const enviornmentAperture = {
  production: false,
  dev: false,
  qa: false,
  local: true,
  apiUrl: 'https://dev-aperture-api.gspann.com/',
  keycloakURL: 'https://dev-auth-aperture.gspann.com',
  pbURL: 'https://dev-pb-aperture.gspann.com/dashboard'
};

export const calculatorEndpoint = {
  production: false,
  dev: false,
  qa: false,
  local: true,
  apiUrl: 'https://dev-calculator-api-aperture.gspann.com/aperture-calculator'
}

export const LMS_ADMIN = {
adminName: "ALMS Admin Test",
adminResourceCode: "IN10080"
}